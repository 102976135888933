import { useParams } from "react-router-dom"

import {
    VerticalSignsGrading as VerticalSignsGradingComponent,
    VerticalSignsGradingError,
    VerticalSignsGradingSkeleton,
} from "../../components/VerticalSignsGrading"
import { useVerticalSigningStateContext } from "../../contexts/VerticalSigningContext"
import { useVerticalSigningGrades } from "../../hooks/queries/verticalSigning/useVerticalSigningGrades"

export function VerticalSignsGrading(props) {
    const { road } = useParams()
    const { filters } = useVerticalSigningStateContext()

    const { data, isError, isLoading } = useVerticalSigningGrades({}, {
        vertical_sign_type__ancestor: filters.type,
        ...(road && { vertical_sign_pole__linear_location_road: road }),
    })

    if (isError) {
        return <VerticalSignsGradingError {...props} />
    }

    if (!data && isLoading) {
        return <VerticalSignsGradingSkeleton {...props} />
    }

    return <VerticalSignsGradingComponent count={data.count} {...props} />
}