import { Navigate, useParams } from "react-router-dom"

import { PropTypes } from "@l2r-front/l2r-proptypes"
import { uuidRegex } from "@l2r-front/l2r-utils"

export function ValidateEventId(props) {

    const { children } = props
    const params = useParams()
    const matchIdFormat = params?.eventId?.match(uuidRegex)

    if (!matchIdFormat) {
        return <Navigate replace to={"../"} />
    }
    return children
}

ValidateEventId.propTypes = {
    children: PropTypes.node,
}