import { useTranslation } from "@l2r-front/l2r-i18n"

import { I18N_NAMESPACE } from "../../../../common/constants/i18n"
import * as Styled from "./VerticalSignTypeList.styled"

export function VerticalSignTypeListError(props) {

    const { t } = useTranslation(I18N_NAMESPACE)

    return <div {...props}>
        <Styled.ErrorTitle>{t(I18N_NAMESPACE, "components.verticalSignTypeList.error")}</Styled.ErrorTitle>
    </div>
}