import { useEffect } from "react"

import { useParams } from "react-router-dom"

import { useGeoServerFeaturesList } from "@l2r-front/l2r-geodata"
import { useImageCollectionDispatchContext } from "@l2r-front/l2r-images"
import { networksQueryKeys, useNetworksDispatchContext, useNetworksStateContext } from "@l2r-front/l2r-networks"
import { PropTypes } from "@l2r-front/l2r-proptypes"

export function DetailSegmentSelectListener(props) {

    const { layerId } = props

    const { setSegmentFeatureIdClicked } = useImageCollectionDispatchContext()
    const { setSelectedLinearLocations } = useNetworksDispatchContext()

    const { road } = useParams()
    const { selectedNetwork } = useNetworksStateContext()
    const layer = layerId || selectedNetwork?.referential?.layerSection

    const queryKeyParams = {
        layer: layer,
        linear_location_road: road,
    }

    const { data: selectedNetworkReferentialFeatures } = useGeoServerFeaturesList(
        networksQueryKeys.features(queryKeyParams),
        queryKeyParams,
        {
            enabled: !!(road && layer),
        })

    useEffect(() => {
        const networkGradingFeature = selectedNetworkReferentialFeatures?.[0]
        if (!networkGradingFeature) {
            return
        }

        const segmentFeature = {
            ...networkGradingFeature,
            properties: {
                ...networkGradingFeature.properties,
                linearLocation: JSON.stringify(networkGradingFeature.linear_locations),
            },
        }
        if (road && !!segmentFeature) {
            setSegmentFeatureIdClicked(segmentFeature.uuid)
            setSelectedLinearLocations(segmentFeature)
        }
    }, [road, setSegmentFeatureIdClicked, selectedNetworkReferentialFeatures, setSelectedLinearLocations])
}

DetailSegmentSelectListener.propTypes = {
    layer: PropTypes.uuid,
}