import { useEffect } from "react"

import {
    Layer as ReactMapLayer,
    useMap,
} from "react-map-gl"

import { PropTypes } from "@l2r-front/l2r-proptypes"

import { useMapDispatchContext } from "../../contexts/MapContext"

export const Layer = (props) => {
    const {
        id,
        interactive,
        onClick,
        ...layerProps
    } = props

    const { current: map } = useMap()
    const { addLayerClickListener, removeLayerClickListener } = useMapDispatchContext()

    useEffect(() => {
        if (!map || !onClick || !interactive) {
            return
        }

        const handleMouseEnter = () => {
            map.getCanvas().style.cursor = "pointer"
        }
        const handleMouseLeave = () => {
            map.getCanvas().style.cursor = ""
        }

        addLayerClickListener(id, onClick)
        map.on("mouseenter", id, handleMouseEnter)
        map.on("mouseleave", id, handleMouseLeave)

        return function cleanup() {
            removeLayerClickListener(id)
            map.off("mouseenter", id, handleMouseEnter)
            map.off("mouseleave", id, handleMouseLeave)
        }
    }, [map, id, interactive, onClick, addLayerClickListener, removeLayerClickListener])

    return <ReactMapLayer id={id} {...layerProps} />
}

Layer.propTypes = {
    ...ReactMapLayer.propTypes,
    id: PropTypes.string.isRequired,
    interactive: PropTypes.bool,
    onClick: PropTypes.func,
}

Layer.defaultProps = {
    interactive: true,
}