export const verticalSigningProjectMulhouse = {
    "uuid": "dc211602-1fc9-4b23-bccf-7a0131827ad8",
    "createdAt": "2023-03-02T08:52:55.457000",
    "updatedAt": "2023-03-02T08:52:55.457000",
}

export const allVerticalSigningProjects = {
    "mulhouse": verticalSigningProjectMulhouse,
}

export const getVerticalSigningProjectBySlug = (networkSlug) => {
    return allVerticalSigningProjects[networkSlug]
}