import { useCallback } from "react"

import { useVerticalSignTypes } from "../queries/verticalSigning/useVerticalSignTypes"

function findCodeIndex(code, catalog, depth) {
    const codeIndex = catalog.findIndex(item => item.code === code)

    if (codeIndex >= 0) {
        return depth
    } else {
        for (let item of catalog) {
            const codeDepth = findCodeIndex(code, item.children, depth + 1)
            if (codeDepth >= 0) {
                return codeDepth
            }
        }

        return -1
    }
}

export function useGetVerticalSignTypeDepth() {
    const { data: verticalSignTypes } = useVerticalSignTypes()

    const getVerticalSignTypeDepth = useCallback((code) => {
        if (!verticalSignTypes) {
            return -1
        }

        return findCodeIndex(code, verticalSignTypes, 0)
    }, [verticalSignTypes])

    return getVerticalSignTypeDepth
}