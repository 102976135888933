import { useMemo } from "react"

import { Route, createBrowserRouter, createRoutesFromElements } from "react-router-dom"

import {
    useAuthenticationStateContext,
    useMyUserContext,
} from "@l2r-front/l2r-auth"
import { ComponentsChainer } from "@l2r-front/l2r-utils"

import { Snackbar } from "../components/Snackbar"
import { appProviders } from "../constants/appProviders"
import { ConfirmAlert } from "../containers/ConfirmAlert"
import { Cgu } from "../pages/Cgu"
import { LoadingPage } from "../pages/LoadingPage"
import { AnonymousRouter } from "./AnonymousRouter"
import { AuthenticatedRouter } from "./AuthenticatedRouter"


const AppRouter = () => {

    const { isAuthenticated, isRefreshing } = useAuthenticationStateContext()
    const { isLoading, user } = useMyUserContext()

    const hasAcceptedTerms = useMemo(() => {
        return user?.hasTermsAccepted
    }, [user])

    if (isLoading || isRefreshing && !user) {
        return <LoadingPage />
    }

    if (user && !hasAcceptedTerms) {
        return <Cgu />
    }

    if (isAuthenticated && hasAcceptedTerms) {
        return <AuthenticatedRouter />
    }

    return <AnonymousRouter />
}

export const router = createBrowserRouter(
    createRoutesFromElements(
        <Route path="*" element={
            <ComponentsChainer components={appProviders}>
                <AppRouter />
                <ConfirmAlert />
                <Snackbar />
            </ComponentsChainer>
        } />,
    ),
)
