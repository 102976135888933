import { useAuthenticatedQuery } from "./useAuthenticatedQuery"

export function useAuthQuery(
    url,
    queryKey,
    queryParameters,
    config = {},
    initialData,
) {
    const apiUrl = `auth/${url}`

    return useAuthenticatedQuery(
        apiUrl,
        queryKey,
        queryParameters,
        config,
        initialData,
    )
}