import { useParams } from "react-router-dom"

import { useNavigateWithSearchParams } from "@l2r-front/l2r-utils"

import { usePoleWithVerticalSigns } from "../../hooks/queries/verticalSigning/usePoleWithVerticalSigns"

export const VerticalSignPoleValidator = (props) => {
    const { children } = props

    const { road, code, poleId } = useParams()
    const navigate = useNavigateWithSearchParams()

    const { isLoading, isError } = usePoleWithVerticalSigns(poleId, {}, {
        onError: () => navigate(code ? `../${road}/code/${code}` : `../${road}`),
    })

    if (isLoading || isError) {
        return null
    } else {
        return children
    }
}