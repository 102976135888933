import { styled } from "@l2r-front/l2r-ui"

import { MobileVerticalSigningMap as VerticalSigningMap } from "../../containers/MobileVerticalSigningMap"
import { VerticalSigningMobileContent as VerticalSigningContent } from "../../containers/VerticalSigningMobileContent"

export const VerticalSigningMobileContent = styled(VerticalSigningContent)(({ theme }) => ({
    paddingTop: theme.spacing(6),
}))

export const Map = styled(VerticalSigningMap)({
    height: "100%",
    width: "100%",
})
