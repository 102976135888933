import { useMemo } from "react"

import { useRoutes } from "react-router-dom"

import { useIsMobileDevice } from "@l2r-front/l2r-ui"

import { SidebarVerticalSignDetail } from "../../containers/SidebarVerticalSignDetail"
import { VerticalSigningSidebar } from "../../containers/VerticalSigningSidebar"
import { useVerticalSigningStateContext } from "../../contexts/VerticalSigningContext"
import { VerticalSignPoleValidator } from "../VerticalSignPoleValidator"
import * as Styled from "./VerticalSigningSidebarRouter.styled"

export function VerticalSigningSidebarRouter() {
    const { ascendantCodes } = useVerticalSigningStateContext()
    const isMobile = useIsMobileDevice()

    const sidebarRoutes = useMemo(() => ([
        { path: "/", element: <VerticalSigningSidebar /> },
        { path: "/:road", element: <VerticalSigningSidebar /> },
        { path: "/:road/:poleId", element: <VerticalSignPoleValidator><SidebarVerticalSignDetail /></VerticalSignPoleValidator> },
    ]), [])

    return <Styled.Sidebar shadowed={!isMobile && ascendantCodes.length >= 2}>{useRoutes(sidebarRoutes)}</Styled.Sidebar>
}
