import {
    useAuthenticatedMutation,
} from "./useAuthenticatedMutation"

export function useAuthMutation(
    url,
    axiosFn,
    queryParameters,
    config = {},
) {
    const apiUrl = `auth/${url}`

    return useAuthenticatedMutation(
        apiUrl,
        axiosFn,
        queryParameters,
        config,
    )
}
