import { useCallback, useEffect } from "react"

import { useMatch, useParams } from "react-router-dom"

import { ImageCollectionLayer, ImageSelectedLayer, useImageCollectionDispatchContext } from "@l2r-front/l2r-images"
import { useMapDispatchContext } from "@l2r-front/l2r-map"
import { NetworkReferentialLayer, useNavigateToLinearLocation, useNetworksDispatchContext } from "@l2r-front/l2r-networks"

import { APP_CITY } from "../../../../common/constants/appCity"
import { MODULE_VERTICAL_SIGNING } from "../../../../common/constants/modules"
import { VerticalSignPolesLayers } from "../../containers/VerticalSignPolesLayers"

export function VerticalSigningMapLayersRouterMobile() {

    const match = useMatch(`:slugId/${APP_CITY}/${MODULE_VERTICAL_SIGNING}/:road/*`)
    const params = useParams()
    const road = params?.road
    const isSegmentView = !!match
    const navigateToLinearLocation = useNavigateToLinearLocation()
    const { setSegmentFeatureIdClicked } = useImageCollectionDispatchContext()
    const { setSearchRoadCallback } = useMapDispatchContext()
    const { setRoadSearched, setSelectedLinearLocations } = useNetworksDispatchContext()
    const matchCode = useMatch(`:slugId/${APP_CITY}/${MODULE_VERTICAL_SIGNING}/code/:code`)

    const processNavigation = useCallback((feature, location) => {
        if (location && location.road && location.road !== road) {
            setSegmentFeatureIdClicked(feature?.properties?.uuid)
            setSelectedLinearLocations(feature)
            const newUrlEnd = matchCode ? `code/${params.code}` : null
            navigateToLinearLocation(location, "..", { urlEnd: newUrlEnd })
        }
    }, [matchCode,
        params,
        setSegmentFeatureIdClicked,
        setSelectedLinearLocations,
        navigateToLinearLocation,
        road])


    const onReferentialFeatureClick = useCallback(feature => {
        const linearLocation = JSON.parse(feature.properties.linearLocation)
        if (linearLocation?.[0]?.road !== road) {
            setRoadSearched(null)
            processNavigation(feature, linearLocation?.[0])
        }
    }, [processNavigation, setRoadSearched, road])

    useEffect(() => {
        setSearchRoadCallback((searchedRoad) => {
            processNavigation(null, { road: searchedRoad })
        })

        return () => setSearchRoadCallback(null)
    }, [
        setSearchRoadCallback,
        processNavigation])

    return (
        <>
            <NetworkReferentialLayer onClickOnFeature={onReferentialFeatureClick} />
            <VerticalSignPolesLayers />
            {isSegmentView && <ImageCollectionLayer road={road} />}
            {isSegmentView && <ImageSelectedLayer />}
        </>
    )
}