import { useMemo } from "react"

import { useRoutes, Navigate } from "react-router-dom"

import { DetailSegmentSelectListener, SegmentSelectListener } from "@l2r-front/l2r-map-utils"
import { MapAdapterBoundingBox, MapAdapterRoad } from "@l2r-front/l2r-networks"
import { useUrlParams, NavigateWithParams } from "@l2r-front/l2r-utils"

import { VerticalSignSelectListener } from "../../containers/VerticalSignSelectListener"
import { VerticalSignPoleValidator } from "../VerticalSignPoleValidator"

export const VerticalSigningDesktopRoutes = () => {
    const { getParams } = useUrlParams()

    const verticalSigningRoutes = useMemo(() => {
        const searchParams = getParams()
        return [
            {
                path: "/",
                element: <>
                    <MapAdapterBoundingBox />
                    <SegmentSelectListener />
                </>,
            },
            {
                path: "/:road",
                element: <>
                    <MapAdapterRoad />
                    <DetailSegmentSelectListener />
                </>,
            },
            {
                path: "/:road/:poleId",
                element: <VerticalSignPoleValidator><VerticalSignSelectListener /></VerticalSignPoleValidator>,
            },
            {
                path: "/code/*",
                element: <Navigate replace to={`../?${searchParams}`}/>,
            },
            {
                path: "/:road/code/*",
                element: <NavigateWithParams replace to={`../:road?${searchParams}`}/>,
            },
        ]
    }, [getParams])

    return useRoutes(verticalSigningRoutes)
}