import { Navigate, useRoutes } from "react-router-dom"

import { MapAdapterBoundingBox } from "@l2r-front/l2r-networks"

import { MapAdapterIncident } from "../../containers/MapAdapterIncident"
import { IncidentDetailsPage } from "../../pages/IncidentDetailsPage"
import { ValidateEventId } from "../../utils/validateEventId"

export const IncidentsDesktopRoutes = () => {
    const incidentsRoutes = useRoutes([
        {
            path: "/:eventId/details",
            element:
                <>
                    <IncidentDetailsPage />
                    <MapAdapterIncident />
                </>,
        },
        {
            path: "/:eventId/*",
            element: <ValidateEventId>
                <MapAdapterIncident />
            </ValidateEventId>,
        },
        {
            path: "/filters",
            element: <Navigate replace to="../" />,
        },
        {
            path: "/*",
            element: <MapAdapterBoundingBox />,
        },
    ])

    return incidentsRoutes
}