export const VERTICAL_SIGNING_CONDITION_GOOD = 10
export const VERTICAL_SIGNING_CONDITION_MEDIUM = 20
export const VERTICAL_SIGNING_CONDITION_BAD = 30
export const VERTICAL_SIGNING_CONDITION_UNKNOWN = -1
export const VERTICAL_SIGNING_CONDITIONS = [
    VERTICAL_SIGNING_CONDITION_GOOD,
    VERTICAL_SIGNING_CONDITION_MEDIUM,
    VERTICAL_SIGNING_CONDITION_BAD,
    VERTICAL_SIGNING_CONDITION_UNKNOWN,
]

export const VERTICAL_SIGNING_CONDITIONS_VALUES = {
    [VERTICAL_SIGNING_CONDITION_BAD]: {
        backgroundColor: "colors/rouge/800",
        color: "DPE/rouge",
        label: "components.verticalSignsCount.bad",
    },
    [VERTICAL_SIGNING_CONDITION_MEDIUM]: {
        backgroundColor: "colors/jaune/800",
        color: "DPE/orange",
        label: "components.verticalSignsCount.medium",
    },
    [VERTICAL_SIGNING_CONDITION_GOOD]: {
        backgroundColor: "colors/vert logiroad/200",
        color: "objects/object-primary",
        label: "components.verticalSignsCount.good",
    },
    [VERTICAL_SIGNING_CONDITION_UNKNOWN]: {
        color: "colors/gris/500",
        label: "",
    },
}