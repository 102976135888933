import { useCallback } from "react"

import { useNavigate } from "react-router-dom"

import { useUrlParams } from "../utils/useUrlParams"

export function useNavigateWithSearchParams() {

    const { getParams } = useUrlParams()
    const navigate = useNavigate()
    const searchParams = getParams()
    const existingSearchParams = Array.from(searchParams.entries()).some(([_, paramValue]) => paramValue.length)

    const navigateWithSearchParams = useCallback((url, paramsToDelete) => {
        const urlContainingParams = url.indexOf("?") >= 0
        if (paramsToDelete?.length) {
            paramsToDelete?.forEach(paramToDelete => {
                searchParams.delete(paramToDelete)
            })
        }
        navigate(existingSearchParams ? `${url}${urlContainingParams ? "&" : "?"}${searchParams}` : url)
    }, [existingSearchParams, searchParams, navigate])

    return navigateWithSearchParams
}