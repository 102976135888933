import { useAuthQuery } from "./useAuthQuery"

export const QUERY_KEY = "user"

export function useMyUserQuery(config) {
    const url = "me/"

    return useAuthQuery(
        url,
        [QUERY_KEY],
        {},
        config,
    )
}
