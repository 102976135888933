import { verticalSigningQueryKeys } from "./queryKeys"
import { useVerticalSigningQueries } from "./useVerticalSigningQuery"
import { useVerticalSignPole } from "./useVerticalSignPoles"

export function usePoleWithVerticalSigns(poleId, parameters = {}, config = {}) {

    const {
        data: poleData,
        isError: poleError,
        isLoading: poleLoading,
    } = useVerticalSignPole(poleId, parameters, config)

    const queries = !poleData?.vertical_signs ? [] : poleData.vertical_signs.map(verticalSign => {
        const url = `vertical_sign_types/${verticalSign.vertical_sign_type}/`
        const queryKey = verticalSigningQueryKeys["verticalSignType"](verticalSign.vertical_sign_type)
        return ({
            url,
            queryKey,
            queryParameters: {},
            config: config,
        })
    })

    const queriesData = useVerticalSigningQueries(queries)
    const isError = poleError || queriesData.some(queryData => queryData.error)
    const isLoading = poleLoading || queriesData.some(queryData => queryData.isLoading)

    const data = (isLoading || isError) ? null : {
        ...poleData,
        vertical_signs: poleData.vertical_signs.map(verticalSign => ({
            ...verticalSign,
            ...queriesData
                .find(query => {
                    return query.data.code === verticalSign.vertical_sign_type
                }).data,
        })),
    }

    return {
        data,
        isError,
        isLoading,
    }
}