import { useMemo } from "react"

import { useTranslation } from "@l2r-front/l2r-i18n"
import { PropTypes } from "@l2r-front/l2r-proptypes"
import { useIsMobileDevice, Typography } from "@l2r-front/l2r-ui"

import { I18N_NAMESPACE } from "../../../../common/constants/i18n"
import { VERTICAL_SIGNING_CONDITIONS_VALUES } from "../../constants/verticalSigningConditions"
import { useVerticalSignTypes } from "../../hooks/queries/verticalSigning/useVerticalSignTypes"
import { findInCatalog, useAscendantCodes } from "../../hooks/utils/useAscendantCodes"
import * as Styled from "./VerticalSignDetail.styled"

export function VerticalSignDetail(props) {

    const { verticalSign } = props

    const ascendantCodes = useAscendantCodes(verticalSign.code)
    const { data: verticalSignsTypes } = useVerticalSignTypes()
    const { t } = useTranslation()
    const isMobile = useIsMobileDevice()

    const codeHierarchy = useMemo(() => {
        if (ascendantCodes.length && verticalSignsTypes) {
            return <Typography variant="H3">
                {ascendantCodes
                    .reverse()
                    .map((code) => findInCatalog(code, verticalSignsTypes).name)
                    .join(" > ")
                }
            </Typography>
        }
        return <Styled.Skeleton />
    }, [ascendantCodes, verticalSignsTypes])

    return <Styled.Container>
        <Styled.Wrapper>
            {codeHierarchy}
        </Styled.Wrapper>
        <Styled.TextWrapper>
            <Styled.Text isMobile={isMobile}>
                {t(I18N_NAMESPACE, "containers.verticalSignDetail.condition.label")} :&nbsp;
            </Styled.Text>
            <Styled.StateWrapper
                color={VERTICAL_SIGNING_CONDITIONS_VALUES[verticalSign.condition].backgroundColor}>
                <Styled.State
                    color={VERTICAL_SIGNING_CONDITIONS_VALUES[verticalSign.condition].color}
                    variant="H3"
                >
                    {t(I18N_NAMESPACE, VERTICAL_SIGNING_CONDITIONS_VALUES[verticalSign.condition].label)}
                </Styled.State>
            </Styled.StateWrapper>
        </Styled.TextWrapper>
        {
            verticalSign?.is_textual && <Styled.TextWrapper>
                <Styled.Text isMobile={isMobile}>
                    {t(I18N_NAMESPACE, "containers.verticalSignDetail.panelText.label")} :&nbsp;
                </Styled.Text>
                <Styled.ContentText
                    color={verticalSign.content ? "colors/gris/1000" : "texts/text-placeholder"}
                    isMobile={isMobile}
                >
                    {verticalSign.content || t(I18N_NAMESPACE, "containers.verticalSignDetail.panelText.text")}
                </Styled.ContentText>
            </Styled.TextWrapper>
        }
    </Styled.Container>
}

VerticalSignDetail.propTypes = {
    verticalSign: PropTypes.verticalSign,
}
