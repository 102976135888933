import { RoadDetailInfo as L2RSegmentDetailInfo } from "@l2r-front/l2r-networks"
import { styled } from "@l2r-front/l2r-ui"

export const Container = styled("div")(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(4),
    padding: theme.spacing(5, 6, 0, 6),
    height: "100%",
    overflowY: "scroll",
}))

export const RoadDetailInfo = styled(L2RSegmentDetailInfo)(({ theme }) => ({
    marginBottom: theme.spacing(6),
}))