import { createContext } from "react"

export const initialTokens = {
    accessToken: null,
    refreshToken: null,    
}

export const initialStateContext = {
    ...initialTokens,
}
const initialDispatchContext = {}

export const AuthenticationStateContext = createContext(initialStateContext)
export const AuthenticationDispatchContext = createContext(initialDispatchContext)
