import { useMemo } from "react"

import { PropTypes } from "@l2r-front/l2r-proptypes"
import { Collapse, List, Typography } from "@l2r-front/l2r-ui"

import { DEFAULT_SIGN_ICON } from "../../constants/defaultIcon"
import * as Styled from "./VerticalSignTypeItem.styled"

export function VerticalSignTypeItem(props) {

    const {
        baseVerticalSignTypeCode,
        children,
        count,
        disabled,
        expandable,
        highlighted,
        icon,
        label,
        onClick,
        selected,
        totalCount,
    } = props

    const isOpen = highlighted || selected
    const displayChildren = !!(expandable && isOpen && children)
    const displayArrowIcon = !disabled && expandable

    const arrowIcon = useMemo(() => {
        return isOpen ? <Styled.ArrowUpIcon color="objects/object-placeholder" />
            : <Styled.ArrowDownIcon color="objects/object-placeholder" />
    }, [isOpen])

    return <>
        <Styled.ListItemButton key={Math.random()}
            disabled={disabled}
            highlighted={highlighted}
            onClick={(disabled || !onClick) ? undefined : onClick}
            selected={selected}
        >
            {(baseVerticalSignTypeCode || icon) && <Styled.Image
                src={icon ?? DEFAULT_SIGN_ICON}
                onError={e => e.target.src = DEFAULT_SIGN_ICON}
            />}
            <Typography variant={(expandable && !disabled) ? "H3" : "Regular"}>{label}</Typography>
            {typeof count === "number" && <Styled.Count variant="Regular">
                {count}
            </Styled.Count>}
            {totalCount && <Typography variant="Small">
                {`/${totalCount}`}
            </Typography>}
            {displayArrowIcon && arrowIcon}
        </Styled.ListItemButton >
        <Collapse in={displayChildren}>
            <List disablePadding>
                {children}
            </List>
        </Collapse>
    </>
}

VerticalSignTypeItem.propTypes = {
    baseVerticalSignTypeCode: PropTypes.string,
    children: PropTypes.node,
    code: PropTypes.string,
    count: PropTypes.number,
    disabled: PropTypes.bool,
    expandable: PropTypes.bool,
    highlighted: PropTypes.bool,
    icon: PropTypes.string,
    label: PropTypes.string.isRequired,
    onClick: PropTypes.func,
    selected: PropTypes.bool,
    startOpen: PropTypes.bool,
    totalCount: PropTypes.number,
}