import { Link as L2RLink } from "react-router-dom"

import { SearchInput as UiSearchInput, SIDEBAR_WIDTH, styled } from "@l2r-front/l2r-ui"

import { AppBar as AppBarComponent } from "../../components/AppBar"
import { APPBAR_HEIGHT, SEARCH_INPUT_WIDTH } from "../../constants/layout"
import { ModuleSelector as L2rModuleSelector } from "../../containers/ModuleSelector"
import { NetworkSelector as L2rNetworkSelector } from "../../containers/NetworkSelector"

export const AppBar = styled(AppBarComponent)(({ theme }) => ({
    zIndex: theme.zIndex.drawer + 2,
}))

export const ChildrenWrapper = styled("div")({
    boxSizing: "border-box",
    display: "flex",
    height: "100%",
    justifyContent: "space-around",
    paddingTop: APPBAR_HEIGHT,
    position: "relative",
    width: "100%",
})

export const ChildrenWrapperWithoutAppBar = styled(ChildrenWrapper)({
    paddingTop: 0,
})

export const Link = styled(L2RLink)({
    alignItems: "center",
    display: "flex",
    height: "100%",
})
export const WrapperRight = styled("div")(({ theme }) => ({
    alignItems: "center",
    display: "flex",
    height: "100%",
    position: "relative",
    width: `calc(100% - ${SIDEBAR_WIDTH}px + ${theme.spacing(9)})`,
}))

export const SearchInput = styled(UiSearchInput)(({ theme }) => ({
    margin: theme.spacing(1, 2, 1, 2),
    width: SEARCH_INPUT_WIDTH,
}))

export const WrapperLeft = styled("div")(({ theme }) => ({
    alignItems: "center",
    display: "flex",
    height: "100%",
    position: "relative",
    width: `calc(${SIDEBAR_WIDTH}px - ${theme.spacing(9)})`,
}))

export const NetworkSelector = styled(L2rNetworkSelector)({
    justifyContent: "center",
    marginLeft: "auto",
    marginRight: "auto",
    width: "auto",
})

export const ModuleSelector = styled(L2rModuleSelector)({
    justifyContent: "center",
    marginRight: "auto",
    width: "auto",
})

export const Logo = styled("img")(() => ({
    height: 36,
}))