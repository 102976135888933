import { useAuthenticatedQuery, useAuthenticatedQueries } from "@l2r-front/l2r-auth"

export function useGeoServerQuery(
    url,
    queryKey,
    queryParameters,
    config = {},
    initialData = undefined,
) {
    const apiUrl = `geoserver/${url}`

    return useAuthenticatedQuery(
        apiUrl,
        queryKey,
        queryParameters,
        config,
        initialData,
    )
}

export function useGeoServerQueries(queriesData) {
    const overridedQueriesData = queriesData?.map(queryData => {

        return {
            url: `geoserver/${queryData.url}`,
            queryKey: queryData.queryKey,
            queryParameters: queryData.queryParameters,
            config: queryData.config,
        }
    })
    return useAuthenticatedQueries(overridedQueriesData)
}