import { getPointSymbolLayerConfig } from "@l2r-front/l2r-map"
import { getColorWithOpacity } from "@l2r-front/l2r-utils"

import { DEFAULT_SIGN_CODE } from "../verticalSigning/constants/defaultIcon"
import {
    VERTICAL_SIGNING_CONDITION_BAD,
    VERTICAL_SIGNING_CONDITION_GOOD,
    VERTICAL_SIGNING_CONDITION_MEDIUM,
    VERTICAL_SIGNING_CONDITIONS,
    VERTICAL_SIGNING_CONDITIONS_VALUES,
} from "../verticalSigning/constants/verticalSigningConditions"
import { DISABLED_STATE, ENABLED_STATE } from "../verticalSigning/containers/VerticalSignPolesSource"

const NOT_SELECTED_SCALE = .8


function getStyleFromPoleCondition(theme, poleId, condition) {
    return [
        ["all", ["==", ["get", "uuid", ["properties"]], poleId], ["==", ["get", "globalCondition", ["properties"]], condition]],
        theme.palette[VERTICAL_SIGNING_CONDITIONS_VALUES[condition].color].main,
        ["all", ["!=", ["get", "uuid", ["properties"]], poleId], ["==", ["get", "globalCondition", ["properties"]], condition]],
        getColorWithOpacity(theme.palette[VERTICAL_SIGNING_CONDITIONS_VALUES[condition].color].main, .3),
    ]
}

function getStyleFromRoadCondition(theme, road, condition) {
    return [
        "case",
        ["all", ["==", ["get", "road", ["get", "linearLocation"]], road], ["==", ["get", "globalCondition", ["properties"]], condition]],
        theme.palette[VERTICAL_SIGNING_CONDITIONS_VALUES[condition].color].main,
        ["all", ["!=", ["get", "road", ["get", "linearLocation"]], road], ["==", ["get", "globalCondition", ["properties"]], condition]],
        getColorWithOpacity(theme.palette[VERTICAL_SIGNING_CONDITIONS_VALUES[condition].color].main, .3),

    ]
}

export function getCircleColor(theme, road, poleId) {
    if (poleId) {
        return [
            "case",
            ...VERTICAL_SIGNING_CONDITIONS.map(condition => {
                return getStyleFromPoleCondition(theme, poleId, condition)
            }),
            ["==", ["get", "uuid", ["properties"]], poleId],
            theme.palette["colors/gris/500"].main,
            ["!=", ["get", "uuid", ["properties"]], poleId],
            getColorWithOpacity(theme.palette["colors/gris/500"].main, .3),
            theme.palette["colors/gris/500"].main,
        ]
    } else if (road) {
        return [
            "case",
            ...VERTICAL_SIGNING_CONDITIONS.map(condition => {
                return getStyleFromRoadCondition(theme, road, condition)
            }),
            ["==", ["get", "road", ["get", "linearLocation"]], road],
            theme.palette["colors/gris/500"].main,
            ["!=", ["get", "road", ["get", "linearLocation"]], road],
            getColorWithOpacity(theme.palette["colors/gris/500"].main, .3),
            theme.palette["colors/gris/500"].main,
        ]
    }

    return [
        "case",
        ["==", ["get", "globalCondition", ["properties"]], VERTICAL_SIGNING_CONDITION_BAD],
        theme.palette[VERTICAL_SIGNING_CONDITIONS_VALUES[VERTICAL_SIGNING_CONDITION_BAD].color].main,
        ["==", ["get", "globalCondition", ["properties"]], VERTICAL_SIGNING_CONDITION_MEDIUM],
        theme.palette[VERTICAL_SIGNING_CONDITIONS_VALUES[VERTICAL_SIGNING_CONDITION_MEDIUM].color].main,
        ["==", ["get", "globalCondition", ["properties"]], VERTICAL_SIGNING_CONDITION_GOOD],
        theme.palette[VERTICAL_SIGNING_CONDITIONS_VALUES[VERTICAL_SIGNING_CONDITION_GOOD].color].main,
        theme.palette["colors/gris/500"].main,
    ]
}

export function getVerticalSignPolesLayerStyle(poleId, road) {
    return getPointSymbolLayerConfig({
        layout: {
            "symbol-sort-key": poleId ? ["case",
                ["!=", ["typeof", poleId], "string"],
                1,
                ["==", ["get", "uuid", ["properties"]], poleId],
                10,
                1,
            ] : road ? ["case",
                ["!=", ["typeof", road], "string"],
                1,
                ["==", ["get", "road", ["get", "linearLocation"]], road],
                10,
                1,
            ] : 1,
            "icon-size": poleId ? ["case",
                ["!=", ["typeof", poleId], "string"],
                1,
                ["==", ["get", "uuid", ["properties"]], poleId],
                1,
                NOT_SELECTED_SCALE,
            ] : road ? ["case",
                ["!=", ["typeof", road], "string"],
                1,
                ["==", ["get", "road", ["get", "linearLocation"]], road],
                1,
                NOT_SELECTED_SCALE,
            ] : 1,
            "icon-image":
                ["coalesce",
                    ["concat",
                        ["get", "globalIcon", ["properties"]],
                        "#",
                        ["get", "globalCondition", ["properties"]],
                        "#",
                        poleId ? ["case",
                            ["!=", ["typeof", poleId], "string"],
                            1,
                            ["==", ["get", "uuid", ["properties"]], poleId],
                            1,
                            NOT_SELECTED_SCALE,
                        ] : !road ? ENABLED_STATE : ["case",
                            ["!=", ["typeof", road], "string"],
                            ENABLED_STATE,
                            ["==", ["get", "road", ["get", "linearLocation"]], road],
                            ENABLED_STATE,
                            DISABLED_STATE,
                        ],
                    ],
                    ["concat",
                        DEFAULT_SIGN_CODE,
                        "#",
                        ["get", "globalCondition", ["properties"]],
                        "#",
                        poleId ? ["case",
                            ["!=", ["typeof", poleId], "string"],
                            ENABLED_STATE,
                            ["==", ["get", "uuid", ["properties"]], poleId],
                            ENABLED_STATE,
                            DISABLED_STATE]
                            : !road ? ENABLED_STATE : ["case",
                                ["!=", ["typeof", road], "string"],
                                ENABLED_STATE,
                                ["==", ["get", "road", ["get", "linearLocation"]], road],
                                ENABLED_STATE,
                                DISABLED_STATE,
                            ],
                    ],
                ],
        },
    })
}