export const allGradesStats = {
    "mulhouse": {
        "count": {
            10: 56,
            20: 57,
            30: 44,
        },
    },
}


export const getVerticalSigningGradesStats = (networkSlug) => {
    return allGradesStats[networkSlug]
}
