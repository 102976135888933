import { useParams } from "react-router-dom"

import { PropTypes } from "@l2r-front/l2r-proptypes"
import { useIsMobileDevice } from "@l2r-front/l2r-ui"

import * as Styled from "./VerticalSignsSidebarComponents.styled"

export function VerticalSignsSidebarComponents(props) {

    const { handleChangeFilter } = props

    const isMobile = useIsMobileDevice()
    const { code, road } = useParams()

    return <>
        {road && <Styled.RoadDetailInfo isMobile={isMobile} road={road} />}
        <Styled.VerticalSignsGrading isMobile={isMobile} />
        <Styled.VerticalSignTypeList
            baseVerticalSignTypeCode={code}
            isMobile={isMobile}
            onChangeFilter={handleChangeFilter}
        />
    </>
}

VerticalSignsSidebarComponents.propTypes = {
    handleChangeFilter: PropTypes.func,
}