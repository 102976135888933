import { useEffect } from "react"

import { useMapDispatchContext } from "@l2r-front/l2r-map"
import { useNetworksDispatchContext } from "@l2r-front/l2r-networks"

export function SegmentSelectListener() {

    const { setSelectedLinearLocations } = useNetworksDispatchContext()

    const { storeMapBoundingBox } = useMapDispatchContext()

    useEffect(() => {
        setSelectedLinearLocations(null)
    }, [setSelectedLinearLocations, storeMapBoundingBox])
}