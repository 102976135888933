import { RoadDetailInfo as L2RSegmentDetailInfo } from "@l2r-front/l2r-networks"
import { styledForMobile } from "@l2r-front/l2r-ui"

import { VerticalSignsGrading as L2RVerticalSignsGrading } from "../../containers/VerticalSignsGrading"
import { VerticalSignTypeList as L2RVerticalSignTypeList } from "../../containers/VerticalSignTypeList"

export const VerticalSignsGrading = styledForMobile(L2RVerticalSignsGrading)(({ isMobile, theme }) => ({
    padding: isMobile ? theme.spacing(0, 0, 8, 0) : theme.spacing(0, 8),
}))

export const VerticalSignTypeList = styledForMobile(L2RVerticalSignTypeList)(({ isMobile, theme }) => ({
    padding: !isMobile && theme.spacing(8),
}))

export const RoadDetailInfo = styledForMobile(L2RSegmentDetailInfo)(({ isMobile, theme }) => ({
    padding: !isMobile && theme.spacing(0, 8, 0, 8),
    marginBottom: theme.spacing(isMobile ? 6 : 5),
}))