import { useMemo, useCallback } from "react"

import { merge } from "lodash"

import {
    Layer,
    MapStyles,
    useMapStateContext,
    LINE_LAYER_SIZE,
    SELECTED_LINE_LAYER_SIZE,
    getLineLayerConfig,
    getInterpolatedLineLayerWidth,
    getInterpolatedSelectableLineLayerWidth,
    getSelectableLineSortKey,
    REFERENTIAL_SOURCE,
    REFERENTIAL_LAYER,
    REFERENTIAL_LAYER_CLICKABLE,
    useOrderingLayers,
    BASE_MAP_ID,
} from "@l2r-front/l2r-map"
import { PropTypes } from "@l2r-front/l2r-proptypes"
import { useTheme } from "@l2r-front/l2r-ui"
import { selectLayerColorOpacity } from "@l2r-front/l2r-utils"

import { useNetworksStateContext } from "../../contexts/NetworksContext"
import { NetworkReferentialSource } from "../NetworkReferentialSource"

export function NetworkReferentialLayer(props) {
    const {
        onClickOnFeature,
        mapId,
        ...layerProps
    } = props

    const { selectedLinearLocations } = useNetworksStateContext()
    const { interactive = true } = layerProps

    const theme = useTheme()
    const { currentMapStyle } = useMapStateContext()

    useOrderingLayers([REFERENTIAL_LAYER_CLICKABLE, REFERENTIAL_LAYER], mapId)

    const referentialLayerConfig = useMemo(() => {
        const selectedRoad = selectedLinearLocations?.[0]?.road || null

        const lineColor = currentMapStyle === MapStyles.plan ? selectLayerColorOpacity(theme.palette.map.darkReferential, 0.5, selectedRoad) : selectLayerColorOpacity(theme.palette.map.lightReferential, 0.85, selectedRoad)
        return merge(
            getLineLayerConfig({
                paint: {
                    "line-color": lineColor,
                    "line-width": getInterpolatedLineLayerWidth(LINE_LAYER_SIZE),
                },
            }),
            {
                paint: {
                    "line-color": lineColor,
                    "line-width": getInterpolatedSelectableLineLayerWidth(selectedRoad),
                },
                layout: {
                    "line-sort-key": getSelectableLineSortKey(selectedRoad),
                },
            },
        )
    }, [currentMapStyle, selectedLinearLocations, theme.palette.map.darkReferential, theme.palette.map.lightReferential])

    const referentialLayerClickConfig = useMemo(() => {
        return getLineLayerConfig({
            "paint": {
                "line-color": "transparent",
                "line-width": getInterpolatedLineLayerWidth(SELECTED_LINE_LAYER_SIZE),
            },
        })
    }, [])

    const handleLayerClick = useCallback(async (event) => {
        const feature = event?.feature
        if (!feature) {
            return
        } else {
            if (onClickOnFeature) {
                onClickOnFeature(feature)
            }
        }
    }, [onClickOnFeature])

    return (
        <NetworkReferentialSource id={REFERENTIAL_SOURCE}>
            <Layer
                {...referentialLayerConfig}
                id={REFERENTIAL_LAYER}
                {...layerProps}
            />
            {interactive && <Layer
                {...referentialLayerClickConfig}
                id={REFERENTIAL_LAYER_CLICKABLE}
                onClick={handleLayerClick}
                {...layerProps}
            />
            }
        </NetworkReferentialSource>
    )
}

NetworkReferentialLayer.propTypes = {
    onClickOnFeature: PropTypes.func,
    mapId: PropTypes.string,
}

NetworkReferentialLayer.defaultProps = {
    mapId: BASE_MAP_ID,
}