import { APP_CITY } from "../constants/appCity"
import { MODULE_VERTICAL_SIGNING } from "../constants/modules"
import { DETAIL_TAB_PARAM_KEY } from "../constants/tabKeys"
import { VERTICAL_SIGNING_CODE_URL_PARAM } from "../constants/urlParams"

export const getModulesBreadcrumb = (searchUrlParams = {}, _) => {
    searchUrlParams.delete(VERTICAL_SIGNING_CODE_URL_PARAM)
    searchUrlParams.delete(DETAIL_TAB_PARAM_KEY)

    return [
        {
            path: `/:project/${APP_CITY}/${MODULE_VERTICAL_SIGNING}/:road`,
            newPath: `${MODULE_VERTICAL_SIGNING}?${searchUrlParams.toString()}`,
        },
        {
            path: `/:project/${APP_CITY}/${MODULE_VERTICAL_SIGNING}/code/*`,
            newPath: `${MODULE_VERTICAL_SIGNING}?${searchUrlParams.toString()}`,
        },
        {
            path: `/:project/${APP_CITY}/${MODULE_VERTICAL_SIGNING}/:road/:poleId`,
            newPath: `${MODULE_VERTICAL_SIGNING}/:road?${searchUrlParams.toString()}`,
        },
        {
            path: `/:project/${APP_CITY}/${MODULE_VERTICAL_SIGNING}/:road/code/*`,
            newPath: `${MODULE_VERTICAL_SIGNING}/:road/?${searchUrlParams.toString()}`,
        },
        {
            path: `/:project/${APP_CITY}/${MODULE_VERTICAL_SIGNING}/:road/code/:codeId/:poleId`,
            newPath: `${MODULE_VERTICAL_SIGNING}/:road/code/:codeId?${searchUrlParams.toString()}`,
        },
    ]
}