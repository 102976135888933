import { findTypeByCode } from "../../../../utils/findTypeByCode"
import { useVerticalSignPoles } from "./useVerticalSignPoles"
import { useVerticalSignTypes } from "./useVerticalSignTypes"


export function useVerticalSignTypesInProject() {
    const { data: verticalSignTypes, isLoading: isVerticalSignTypesLoading, isError: isVerticalSignTypesError } = useVerticalSignTypes()
    const { data: verticalSignPoles, isLoading: isVerticalSignPolesLoading, isError: isVerticalSignPolesError } = useVerticalSignPoles()

    const isLoading = isVerticalSignTypesLoading || isVerticalSignPolesLoading
    const isError = isVerticalSignTypesError || isVerticalSignPolesError

    if (verticalSignPoles && verticalSignTypes) {
        const typeCodesInPoles = new Set()
        verticalSignPoles.forEach(pole => {
            pole.vertical_signs.forEach(sign => typeCodesInPoles.add(sign.vertical_sign_type))
        })

        const typeUsedInProject = []
        for (const code of typeCodesInPoles) {
            const type = findTypeByCode(code, verticalSignTypes)
            typeUsedInProject.push(type)
        }

        return { data: typeUsedInProject, isLoading, isError }
    }


    return { data: null, isLoading, isError }
}