import { useMutation } from "@tanstack/react-query"
import { useLocalStorage } from "@uidotdev/usehooks"

import { 
    useAlertsDispatchContext,
    onMutationSuccessFeedback,
    onMutationErrorFeedback,
    useL2rMutation,
    l2rApiAxiosInstance as axiosInstance,
    STATUS_CODE_UNAUTHORIZED,
} from "@l2r-front/l2r-query"

import { initialTokens } from "../../contexts/AuthenticationContext/AuthenticationContext.context"
import { useLogout } from "../useLogout"

async function mutationFn(
    axiosFn,
    url,
    config,
    values,
) {
    const headers = config?.headers
    const response = await axiosFn(url, values, headers)
    return response.data
}

export function useAuthenticatedMutation(
    url,
    axiosFn,
    _,
    config = {},
) {
    const { setSnackbar } = useAlertsDispatchContext()
    const [localState, setLocalState] = useLocalStorage("authentication", initialTokens)
    const logout = useLogout()
    const { mutateAsync: refreshTokenMutation, isPending: isLoadingToken } = useL2rMutation("auth/token/refresh/", axiosInstance.post)

    const mutation = useMutation({
        mutationFn: (value) => mutationFn(axiosFn, url, config, value),
        ...config,
        onSuccess: (values) => onMutationSuccessFeedback(config, setSnackbar, values),
        onError: (error, variables) => {
            if (error?.response?.status === STATUS_CODE_UNAUTHORIZED && !!localState.accessToken && !isLoadingToken) {
                refreshTokenMutation({ refresh: localState.refreshToken })
                    .then(response => {
                        const { access, refresh } = response
                        setLocalState({
                            accessToken: access,
                            refreshToken: refresh,
                        })
                        mutation.reset()
                        mutation.mutate(variables)
                    })
                    .catch(() => {
                        logout()
                    })
            } else {
                onMutationErrorFeedback(config, setSnackbar, error)
            }
        },
    },
    )

    return mutation
}