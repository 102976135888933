import { useCallback, useEffect, useMemo } from "react"

import { getYear } from "date-fns"
import _ from "lodash"

import { useImageCollectionDispatchContext } from "@l2r-front/l2r-images"
import { PropTypes } from "@l2r-front/l2r-proptypes"
import { useUrlParams } from "@l2r-front/l2r-utils"

import { VERTICAL_SIGNING_CODE_URL_PARAM, VERTICAL_SIGNING_CONDITION_URL_PARAM } from "../../../../common/constants/urlParams"
import { useAscendantCodes } from "../../hooks/utils/useAscendantCodes"
import { useDescendantCodes } from "../../hooks/utils/useDescendantCodes"
import { VerticalSigningStateContext, VerticalSigningDispatchContext } from "./VerticalSigningContext.context"

export const VerticalSigningContextProvider = (props) => {

    const { children } = props
    const { getParam, setParam, deleteParam } = useUrlParams()
    const { setImageCollectionCurrentYear } = useImageCollectionDispatchContext()
    const typeFilter = getParam(VERTICAL_SIGNING_CODE_URL_PARAM)
    const conditionFilter = getParam(VERTICAL_SIGNING_CONDITION_URL_PARAM)

    const ascendantCodes = useAscendantCodes(typeFilter)
    const descendantCodes = useDescendantCodes(typeFilter)

    const setFilters = useCallback((newFilters) => {
        if (newFilters?.type && typeFilter !== newFilters.type) {
            setParam(VERTICAL_SIGNING_CODE_URL_PARAM, newFilters.type)
        } else if (newFilters.type === null) {
            deleteParam(VERTICAL_SIGNING_CODE_URL_PARAM)
        }

        if (newFilters?.condition) {
            let conditionFilterArray = conditionFilter?.split(",") || []
            if (conditionFilterArray.includes(newFilters.condition)) {
                const index = conditionFilterArray.findIndex(condition => {
                    return condition === newFilters.condition
                })
                conditionFilterArray.splice(index, 1)
            } else {
                conditionFilterArray.push(newFilters.condition)
            }
            if (conditionFilterArray.length) {
                setParam(VERTICAL_SIGNING_CONDITION_URL_PARAM, conditionFilterArray.join(","))
            } else {
                deleteParam(VERTICAL_SIGNING_CONDITION_URL_PARAM)
            }
        }
    }, [conditionFilter, deleteParam, setParam, typeFilter])

    const stateValue = useMemo(() => {
        return {
            ascendantCodes,
            descendantCodes,
            filters: { type: typeFilter, conditions: conditionFilter },
        }
    }, [ascendantCodes,
        conditionFilter,
        descendantCodes,
        typeFilter,
    ])

    const dispatchValue = useMemo(() => {
        return {
            setFilters,
        }
    }, [setFilters])

    useEffect(() => {
        const currentYear = getYear(new Date())
        setImageCollectionCurrentYear(parseInt(currentYear))
    }, [setImageCollectionCurrentYear])

    return (
        <VerticalSigningStateContext.Provider value={stateValue}>
            <VerticalSigningDispatchContext.Provider value={dispatchValue}>
                {children}
            </VerticalSigningDispatchContext.Provider>
        </VerticalSigningStateContext.Provider>
    )
}

VerticalSigningContextProvider.propTypes = {
    children: PropTypes.node,
}
