import { useMemo, useCallback } from "react"

import {
    IMAGE_SELECTED_SOURCE,
    IMAGE_SELECTED_LAYER,
    IMAGE_SELECTED_BORDER_LAYER,
    useOrderingLayers,
    Layer,
} from "@l2r-front/l2r-map"

import { useImageCollectionDispatchContext } from "../../contexts/ImageCollectionContext"
import { getImageSelectedBorderConfig, getImageSelectedConfig } from "../../utils/getImageCollectionConfig"
import { ImageSelectedSource } from "../ImageSelectedSource"

export function ImageSelectedLayer() {

    const { setImageFeatureIdClicked } = useImageCollectionDispatchContext()

    const imageSelectedLayerConfig = useMemo(() => {
        return getImageSelectedConfig()
    }, [])

    const imageSelectedLayerBorderConfig = useMemo(() => {
        return getImageSelectedBorderConfig()
    }, [])

    useOrderingLayers([IMAGE_SELECTED_BORDER_LAYER, IMAGE_SELECTED_LAYER])

    const handleClick = useCallback(async (event) => {
        const feature = event?.feature

        if (!feature) {
            return
        } else {
            setImageFeatureIdClicked(feature.properties.uuid)
        }
    }, [setImageFeatureIdClicked])

    return (
        <ImageSelectedSource
            id={IMAGE_SELECTED_SOURCE}
            tolerance={.001}
        >
            <Layer
                id={IMAGE_SELECTED_BORDER_LAYER}
                {...imageSelectedLayerBorderConfig}
                onClick={handleClick}
            />
            <Layer
                id={IMAGE_SELECTED_LAYER}
                {...imageSelectedLayerConfig}
            />
        </ImageSelectedSource>
    )
}