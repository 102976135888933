import { useEffect } from "react"

import { bbox, featureCollection } from "@turf/turf"
import { useParams } from "react-router-dom"

import { useImageCollectionDispatchContext } from "@l2r-front/l2r-images"
import { useMapDispatchContext } from "@l2r-front/l2r-map"
import { CANVAS_MIN_BBOX_WIDTH, useNetworksDispatchContext } from "@l2r-front/l2r-networks"

import { useVerticalSignPole } from "../../hooks/queries/verticalSigning/useVerticalSignPoles"

export function VerticalSignSelectListener() {

    const { getMapRef, setMapBoundingBoxToFit } = useMapDispatchContext()
    const { setSegmentFeatureIdClicked } = useImageCollectionDispatchContext()
    const { setSelectedLinearLocations } = useNetworksDispatchContext()

    const { road, poleId } = useParams()
    const map = getMapRef()?.getMap()

    const { data: currentFeature } = useVerticalSignPole(poleId, { as_geojson: true })

    useEffect(() => {
        if (!currentFeature) {
            return
        }

        const pointFeature = {
            ...currentFeature,
            properties: {
                ...currentFeature.properties,
                linearLocation: JSON.stringify([currentFeature.linear_location]),
            },
        }
        if (road && poleId && !!pointFeature) {
            setSegmentFeatureIdClicked(pointFeature.uuid)
            setSelectedLinearLocations(pointFeature)

            const roadFeature = featureCollection([pointFeature])
            const [minLng, minLat, maxLng, maxLat] = bbox(roadFeature)
            const lngOffset = Math.max((CANVAS_MIN_BBOX_WIDTH - (maxLng - minLng)), 0) / 2
            const roadBoundingBox = {
                minLng: minLng - lngOffset,
                minLat,
                maxLng: maxLng + lngOffset,
                maxLat,
            }

            if (map) {
                setMapBoundingBoxToFit(roadBoundingBox)
            }
        }
    }, [currentFeature, map, poleId, road, setMapBoundingBoxToFit, setSegmentFeatureIdClicked, setSelectedLinearLocations])

    return null
}
