import { useRoutes } from "react-router-dom"

import { DetailSegmentSelectListener, SegmentSelectListener } from "@l2r-front/l2r-map-utils"
import { MapAdapterBoundingBox, MapAdapterRoad } from "@l2r-front/l2r-networks"

import { VerticalSignSelectListener } from "../../containers/VerticalSignSelectListener"
import { MobileRoadVerticalSigningPage } from "../../pages/MobileRoadVerticalSigningPage"
import { MobileVerticalPoleSigningPage } from "../../pages/MobileVerticalPoleSigningPage/MobileVerticalPoleSigningPage"
import { MobileVerticalSigningPage } from "../../pages/MobileVerticalSigningPage"
import { VerticalSignPoleValidator } from "../VerticalSignPoleValidator"

export const VerticalSigningMobileRoutes = () => {

    const roadwayRoutes = useRoutes([
        {
            path: "/*",
            element: <>
                <MapAdapterBoundingBox />
                <MobileVerticalSigningPage />
                <SegmentSelectListener />
            </>,
        },
        {
            path: "/code/:code",
            element: <>
                <MapAdapterBoundingBox />
                <MobileVerticalSigningPage />
                <SegmentSelectListener />
            </>,
        },
        {
            path: "/:road",
            element: <>
                <DetailSegmentSelectListener />
                <MapAdapterRoad />
                <MobileRoadVerticalSigningPage />
            </>,
        },
        {
            path: "/:road/:poleId",
            element: <VerticalSignPoleValidator>
                <VerticalSignSelectListener />
                <MobileVerticalPoleSigningPage />
            </VerticalSignPoleValidator>,
        },
        {
            path: "/:road/code/:code",
            element: <>
                <DetailSegmentSelectListener />
                <MapAdapterRoad />
                <MobileRoadVerticalSigningPage />,
            </>,
        },
        {
            path: "/:road/code/:code/:poleId",
            element: <VerticalSignPoleValidator>
                <VerticalSignSelectListener />
                <MobileVerticalPoleSigningPage />,
            </VerticalSignPoleValidator>,
        },
    ])

    return roadwayRoutes
}