import React, { useMemo } from "react"

import { useTheme } from "@emotion/react"
import { useMeasure } from "react-use"

import { PropTypes } from "@l2r-front/l2r-proptypes"

import * as Styled from "./TabulatedLayout.styled"

export function TabulatedLayout(props) {

    const {
        className,
        handleChange,
        tabsConfig,
        tabElement,
        value,
        ...otherProps
    } = props

    const theme = useTheme()
    const [ref, { height }] = useMeasure()

    const tabsAttributes = useMemo(() => {
        return tabsConfig.map((config, index) => {
            return {
                active: true,
                icon: config.icon,
                iconPosition: "start",
                id: config.id,
                label: config.label,
                value: index,
            }
        })
    }, [tabsConfig])

    const tabsPanels = useMemo(() => {
        return tabsConfig.map((config, index) => {
            const children = config?.element

            return (<Styled.TabPanel
                currentTabIndex={value}
                height={height}
                index={index}
                key={`tab-panel-${index}`}
                margin={!config.fullScreen}
            >
                {children}
            </Styled.TabPanel>
            )
        })
    }, [height, tabsConfig, value])

    return (
        <Styled.Container className={className}>
            <div ref={ref}>
                <Styled.Tabs
                    handleChangeTab={handleChange}
                    tabsAttributes={tabsAttributes}
                    tabElement={tabElement}
                    TabIndicatorProps={{ style: { background: theme.palette["cta-bg/cta-bg-primary"].main } }}
                    value={value}
                    variant="fullWidth"
                    {...otherProps}
                />
            </div>
            {tabsPanels}
        </Styled.Container>
    )
}

TabulatedLayout.propTypes = {
    className: PropTypes.string,
    handleChange: PropTypes.func.isRequired,
    tabsConfig: PropTypes.arrayOf(PropTypes.shape({
        element: PropTypes.element,
        icon: PropTypes.element,
        id: PropTypes.string,
        label: PropTypes.string,
    }).isRequired),
    value: PropTypes.number.isRequired,
}
