import { keepPreviousData } from "@tanstack/react-query"

import { verticalSigningQueryKeys } from "./queryKeys"
import { useVerticalSigningQuery } from "./useVerticalSigningQuery"


export function useVerticalSigningGrades(config = {}, parameters = {}) {
    const url = "vertical_signs/stats/conditions/"

    const queryKey = verticalSigningQueryKeys["stats_conditions"]({ parameters })

    return useVerticalSigningQuery(
        url,
        queryKey,
        parameters,
        {
            ...config,
            placeholderData: keepPreviousData,
        },
    )
}
