import { useMemo } from "react"

import { useRoutes } from "react-router-dom"

import { SegmentSelectListener } from "@l2r-front/l2r-map-utils"
import { MapAdapterRoad, MapAdapterBoundingBox } from "@l2r-front/l2r-networks"

import { RoadwayDetailSegmentSelectListener } from "../../containers/RoadwayDetailSegmentSelectListener"

export const RoadwayDesktopRoutes = () => {

    const roadwayRoutes = useMemo(() => ([
        {
            path: "/",
            element: <>
                <MapAdapterBoundingBox />
                <SegmentSelectListener />
            </>,
        },
        {
            path: "/:road/*",
            element: <>
                <RoadwayDetailSegmentSelectListener />
                <MapAdapterRoad />
            </>,
        },
    ]), [])

    return useRoutes(roadwayRoutes)
}