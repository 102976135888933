import { PropTypes } from "@l2r-front/l2r-proptypes"

import * as Styled from "./Sidebar.styled"

export function Sidebar(props) {
    const {
        className,
        children,
        shadowed,
    } = props

    return (
        <Styled.Drawer className={className} variant="permanent" shadowed={shadowed}>
            {children}
        </Styled.Drawer>
    )
}

Sidebar.propTypes = {
    className: PropTypes.string,
    children: PropTypes.node,
    shadowed: PropTypes.bool,
}

Sidebar.defaultProps = {
    shadowed: false,
}