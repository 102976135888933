import { VerticalSignTypeItemSkeleton } from "../../components/VerticalSignTypeItem"
import * as Styled from "./VerticalSignTypeList.styled"

export function VerticalSignTypeListSkeleton(props) {

    return <div {...props}>
        <Styled.TitleSkeleton />
        {Array.from({ length: 5 })
            .map((_, index) => {
                return <VerticalSignTypeItemSkeleton key={`verticalSignTypeListSkeleton-${index}`} />
            })}
    </div>
}