import { useCallback } from "react"

import { useQueryClient } from "@tanstack/react-query"
import { useLocalStorage } from "@uidotdev/usehooks"
import { useNavigate } from "react-router-dom"

import { initialTokens } from "../contexts/AuthenticationContext/AuthenticationContext.context"

export const useLogout = () => {
    const queryClient = useQueryClient()
    const [_, setLocalState] = useLocalStorage("authentication", initialTokens)
    const navigate = useNavigate()

    const logout = useCallback(() => {
        setLocalState(initialTokens)
        queryClient.clear()
        navigate("/")
    }, [setLocalState, queryClient, navigate])

    return logout
}