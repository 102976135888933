export function replaceSearchParams(params, acceptedParams) {
    return (params || "")
        .replaceAll("?", "")
        .split("&")
        .filter(search => {
            return acceptedParams.some(param => {
                return search.split("=")[0] === param
            })
        })
        .reduce((acc, v, index) => {
            return index === 0 ? `?${v}` : `${acc}&${v}`
        }, "")
}
