import { allReferentials } from "../geoserver/referentials"

export const networkEmpty =
{
    "uuid": "e0a99049-ecc4-4995-b9a5-151d3bb7271e",
    "createdAt": "2023-01-06T09:54:57.872000",
    "updatedAt": "2023-03-14T13:32:03.617000",
    "name": "Vide",
    "nameLong": "Projet Vide",
    "referential": {
        "uuid": "1c8b8447-cda0-441c-bb39-43565b4e168d",
        "name": "CD91_Small_ref",
        "layerSection": "f9f67e4b-b3ac-466d-aa89-3e31601da395",
        "layerMarker": null,
    },
    "slug": "cd44",
    "country": "FR",
    "boundingBox": {
        "type": "Polygon",
        "coordinates": [
            [
                [
                    2.215755120975147,
                    48.56624403459183,
                ],
                [
                    2.215755120975147,
                    48.60839398782035,
                ],
                [
                    2.286325092683901,
                    48.60839398782035,
                ],
                [
                    2.286325092683901,
                    48.56624403459183,
                ],
                [
                    2.215755120975147,
                    48.56624403459183,
                ],
            ],
        ],
    },
    "modules": [
        "INCIDENTS",
        "ROADWAY",
    ],
    "role": null,
}

export const networkWithoutModule =
{
    "uuid": "93fd06af-81c2-4dd7-b477-7f4a06f4571e",
    "createdAt": "2023-01-11T14:06:38.785000",
    "updatedAt": "2023-03-14T13:32:03.610000",
    "name": "Network_without_module",
    "nameLong": "Network without any module",
    "referential": {
        "uuid": "1c8b8447-cda0-441c-bb39-43565b4e168d",
        "name": "CD91_Small_ref",
        "layerSection": "f9f67e4b-b3ac-466d-aa89-3e31601da395",
        "layerMarker": null,
    },
    "slug": "network_without_module",
    "country": "FR",
    "boundingBox": {
        "type": "Polygon",
        "coordinates": [
            [
                [
                    2.215755120975147,
                    48.56624403459183,
                ],
                [
                    2.215755120975147,
                    48.60839398782035,
                ],
                [
                    2.286325092683901,
                    48.60839398782035,
                ],
                [
                    2.286325092683901,
                    48.56624403459183,
                ],
                [
                    2.215755120975147,
                    48.56624403459183,
                ],
            ],
        ],
    },
    "modules": [],
    "role": null,
}

export const networkCD91Small =
{
    "uuid": "a36d1507-aaf5-462f-9c66-dcf36187912f",
    "createdAt": "2023-03-01T15:52:58.170000",
    "updatedAt": "2023-03-01T15:54:29.575000",
    "name": "CD91_Small",
    "nameLong": "Conseil départemental de l'Essonne",
    "referential": {
        "uuid": "1c8b8447-cda0-441c-bb39-43565b4e168d",
        "name": "CD91_Small_ref",
        "layerSection": "f9f67e4b-b3ac-466d-aa89-3e31601da395",
        "layerMarker": null,
    },
    "slug": "cd91_small",
    "country": "FR",
    "boundingBox": {
        "type": "Polygon",
        "coordinates": [
            [
                [
                    2.215755120975147,
                    48.56624403459183,
                ],
                [
                    2.215755120975147,
                    48.60839398782035,
                ],
                [
                    2.286325092683901,
                    48.60839398782035,
                ],
                [
                    2.286325092683901,
                    48.56624403459183,
                ],
                [
                    2.215755120975147,
                    48.56624403459183,
                ],
            ],
        ],
    },
    "modules": [
        "INCIDENTS",
        "ROADWAY",
    ],
    "role": null,
}

export const networkMulhouse =
{
    "uuid": "95de8eef-3c12-4656-aa1e-9f6dae3895ba",
    "createdAt": "2023-03-01T15:52:58.170000",
    "updatedAt": "2023-03-01T15:54:29.575000",
    "name": "Mulhouse",
    "nameLong": "Métropole de Mulhouse",
    "referential": {
        "uuid": "72de0a35-facb-4958-a852-ba3dc706b8c8",
        "name": "Mulhouse_ref",
        "layerSection": "25146602-9f3b-4b93-9b54-aaa81dbbfa82",
        "layerMarker": null,
    },
    "slug": "mulhouse",
    "country": "FR",
    "boundingBox": {
        "type": "Polygon",
        "coordinates": [
            [
                [
                    7.282927434990326,
                    47.7227360164458,
                ],
                [
                    7.282927434990326,
                    47.78045032410036,
                ],
                [
                    7.3685043003713,
                    47.78045032410036,
                ],
                [
                    7.3685043003713,
                    47.7227360164458,
                ],
                [
                    7.282927434990326,
                    47.7227360164458,
                ],
            ],
        ],
    },
    "modules": [
        "VERTICAL_SIGNING",
        "ROADWAY",
        "ROADWORK",
    ],
    "role": null,
}

export const allNetworks = [
    networkEmpty,
    networkWithoutModule,
    networkCD91Small,
    networkMulhouse,
]

export const getNetworkBySlug = (networkSlug) => {
    return allNetworks.find(network => network.slug === networkSlug)
}

export const getNetworkRoadsBySlug = (networkSlug) => {
    const network = allNetworks.find(network => network.slug === networkSlug)
    const referentialLayer = allReferentials[network.referential.layerSection]
    const roads = referentialLayer.features.map(feature => feature.linear_location[0].road)
    return [... new Set(roads)]
}