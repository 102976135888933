import _ from "lodash"

export function getClusterCountLayerConfig(config) {
    const baseConfig = {
        type: "symbol",
        filter: ["has", "point_count"],
        layout: {
            "text-field": ["get", "point_count_abbreviated"],
            "text-font": ["Poppins"],
        },
    }

    return _.merge(baseConfig, config)
}
