import { useTranslation } from "@l2r-front/l2r-i18n"

import { I18N_NAMESPACE } from "../../../../common/constants/i18n"
import * as Styled from "./VerticalSignsGrading.styled"

export function VerticalSignsGradingError(props) {

    const { t } = useTranslation(I18N_NAMESPACE)

    return <div {...props}>
        <Styled.ErrorText>{t(I18N_NAMESPACE, "components.verticalSignsGrading.error")}</Styled.ErrorText>
    </div>
}