import { useTranslation } from "@l2r-front/l2r-i18n"
import { PropTypes } from "@l2r-front/l2r-proptypes"
import { Typography } from "@l2r-front/l2r-ui"

import { I18N_NAMESPACE } from "../../../../common/constants/i18n"
import { VerticalSignTypeItem } from "../VerticalSignTypeItem/VerticalSignTypeItem"
import * as Styled from "./VerticalSignTypeList.styled"

export function VerticalSignTypeList(props) {

    const {
        baseVerticalSignTypeCode,
        onClick,
        className,
        data,
        ascendantCodes,
        filter,
        title,
        VerticalSignsCount,
    } = props

    const { t } = useTranslation(I18N_NAMESPACE)

    return <div className={className}>
        <Styled.Header>
            <Typography variant="H2">
                {title}
            </Typography>
            {VerticalSignsCount && <VerticalSignsCount />}
        </Styled.Header>
        {data?.length ? <Styled.List>
            {
                data.map((dataItem) => {
                    const children = dataItem.children.length ? dataItem.children.map(child => {
                        return <VerticalSignTypeItem
                            icon={child.icon}
                            key={child.code}
                            expandable={false}
                            highlighted={filter !== child.code && (ascendantCodes || []).includes(child.code)}
                            label={child.name}
                            onClick={() => onClick(child.code)}
                            selected={filter === child.code}
                        />
                    })
                        : null

                    return <VerticalSignTypeItem
                        baseVerticalSignTypeCode={baseVerticalSignTypeCode}
                        expandable={!!children}
                        key={dataItem.code}
                        {...dataItem}
                        children={children}
                        highlighted={filter !== dataItem.code && (ascendantCodes || []).includes(dataItem.code)}
                        label={dataItem.name}
                        onClick={() => onClick(dataItem.code)}
                        selected={filter === dataItem.code}
                        startOpen={children && (ascendantCodes || []).includes(dataItem.code)}
                    />
                })
            }
        </Styled.List>
            : <Typography>{t(I18N_NAMESPACE, "components.verticalSignTypeList.emptyList")}</Typography>}
    </div>
}

VerticalSignTypeList.propTypes = {
    onClick: PropTypes.func.isRequired,
    baseVerticalSignTypeCode: PropTypes.string,
    className: PropTypes.string,
    data: PropTypes.any.isRequired,
    ascendantCodes: PropTypes.arrayOf(PropTypes.string),
    filter: PropTypes.oneOfType([PropTypes.string, () => null]),
    title: PropTypes.string.isRequired,
    VerticalSignsCount: PropTypes.node,
}