import { useAuthenticatedMutation } from "@l2r-front/l2r-auth"
import { useNetworksStateContext } from "@l2r-front/l2r-networks"

export function useSvraiMutation(
    url,
    axiosFn,
    queryParameters,
    config = {},
) {
    const apiUrl = `svrai/${url}`
    const { enabled = true } = config
    const { selectedNetwork } = useNetworksStateContext()

    return useAuthenticatedMutation(
        apiUrl,
        axiosFn,
        { ...queryParameters, project: selectedNetwork?.slug },
        {
            ...config,
            enabled: !!selectedNetwork?.slug && enabled,
        },
    )
}