import { useTranslation } from "@l2r-front/l2r-i18n"
import { PropTypes } from "@l2r-front/l2r-proptypes"
import { useMediaQuery, useTheme } from "@l2r-front/l2r-ui"

import { I18N_NAMESPACE } from "../../../../common/constants/i18n"
import * as Styled from "./VerticalSignsCount.styled"

export function VerticalSignsCount(props) {

    const {
        color,
        count,
        label,
        onClick,
        selected,
    } = props

    const { t } = useTranslation(I18N_NAMESPACE)
    const theme = useTheme()
    const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"))

    return <Styled.VerticalSignsCountButton onClick={onClick} isMobile={isSmallScreen} selected={selected}>
        <Styled.Icon color={color} isMobile={isSmallScreen} />
        <Styled.TextWrapper>
            <Styled.CountText variant="H3">{count}</Styled.CountText>
            <Styled.StateText isMobile={isSmallScreen} variant="Small">{t(I18N_NAMESPACE, label)}</Styled.StateText>
        </Styled.TextWrapper>
    </Styled.VerticalSignsCountButton>
}

VerticalSignsCount.propTypes = {
    color: PropTypes.string.isRequired,
    count: PropTypes.number.isRequired,
    label: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
    selected: PropTypes.bool,
}