import { SidebarContent as L2RSidebarContent, styled, styledForMobile } from "@l2r-front/l2r-ui"

export const Container = styled("div")(() => ({
    height: "100%",
    overflowY: "auto",
}))

export const SidebarContent = styledForMobile(L2RSidebarContent)(({ isMobile }) => ({
    height: "100%",
    overflow: isMobile && "auto",
    width: "100%",
}))