import { RoadDetailInfo as L2RSegmentDetailInfo } from "@l2r-front/l2r-networks"
import {
    Typography,
    styled,
    styledForMobile,
    SIDEBAR_SPACING,
    SIDEBAR_SPACING_MOBILE,
} from "@l2r-front/l2r-ui"

import { RoadwayDetailYearSelector as L2RRoadwayDetailYearSelector } from "../RoadwayDetailYearSelector"

export const Container = styled("div")(() => ({
    height: "100%",
    overflowY: "auto",
}))

export const HeaderWrapper = styledForMobile("div")(({ theme, isMobile }) => ({
    margin: isMobile ? theme.spacing(0, SIDEBAR_SPACING_MOBILE, 5, SIDEBAR_SPACING_MOBILE)
        : theme.spacing(0, SIDEBAR_SPACING, 5, SIDEBAR_SPACING),
}))

export const RoadDetailInfo = styled(L2RSegmentDetailInfo)(() => ({
    margin: 0,
}))

export const RoadwayDetailYearselector = styled(L2RRoadwayDetailYearSelector)(({ theme }) => ({
    height: "auto",
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(5),
    width: "100%",
}))

export const NetworkGradingSelectorDescription = styled(Typography)(({ theme }) => ({
    display: "block",
    paddingTop: theme.spacing(2),
    width: "100%",
}))