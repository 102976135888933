import { Navigate, useRoutes } from "react-router-dom"

import { useRoleIsReadOnly, MapAdapterBoundingBox } from "@l2r-front/l2r-networks"

import { useSvraiStateContext } from "../../../../common/contexts/SvraiContext"
import { MapAdapterIncident } from "../../containers/MapAdapterIncident"
import { IncidentAnalysePage } from "../../pages/IncidentAnalyzePage/IncidentAnalyzePage"
import { MobileIncidentDetailsPage } from "../../pages/MobileIncidentDetailsPage"
import { IncidentsFiltersMobilePage } from "../../pages/MobileIncidentsFiltersPage"
import { MobileIncidentsPage } from "../../pages/MobileIncidentsPage"
import { ValidateEventId } from "../../utils/validateEventId"

export const IncidentsMobileRoutes = () => {
    const { selectedIncidentUuid } = useSvraiStateContext()
    const readOnly = useRoleIsReadOnly()

    const incidentsRoutes = useRoutes([
        {
            path: "/:eventId/details",
            element: <Navigate replace to="../" />,
        },
        {
            path: "/:eventId/analysis/*",
            element: readOnly ? <Navigate replace to={`../${selectedIncidentUuid}`} /> : <IncidentAnalysePage />,
        },
        {
            path: "/:eventId/*",
            element:
                <ValidateEventId>
                    <MapAdapterIncident />
                    <MobileIncidentDetailsPage />
                </ValidateEventId>,
        },
        {
            path: "/filters/*",
            element: <IncidentsFiltersMobilePage />,
        },
        {
            path: "/*",
            element:
                <>
                    <MapAdapterBoundingBox />
                    <MobileIncidentsPage />
                </>,
        },
    ])

    return incidentsRoutes
}