import { array, bool, oneOf, shape, string } from "prop-types"

export const verticalSign = shape({
    children: array,
    code: string,
    condition: oneOf([10, 20, 30]),
    content: string,
    country: oneOf(["FR", "EN", "ES"]),
    description: string,
    icon: string,
    is_main: bool,
    is_textual: bool,
    name: string,
    name_long: string,
    parent: string,
    vertical_sign_type: string,
})