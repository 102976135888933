import { useParams } from "react-router-dom"

import { useNavigateWithSearchParams } from "@l2r-front/l2r-utils"

import { useRoadworkWithTasks } from "../../hooks/queries/useRoadwork"

export const RoadworkValidator = (props) => {
    const { children } = props

    const { road, roadworkUuid } = useParams()
    const navigate = useNavigateWithSearchParams()

    const { isLoading, isError } = useRoadworkWithTasks(roadworkUuid, road, {
        onError: () => navigate(`../${road}`),
    })

    if (isLoading || isError) {
        return null
    } else {
        return children
    }
}