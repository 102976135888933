import { useCallback, useEffect, useMemo } from "react"

import { useParams } from "react-router-dom"

import { useTranslation } from "@l2r-front/l2r-i18n"
import { useMapDispatchContext } from "@l2r-front/l2r-map"
import { useNetworksDispatchContext } from "@l2r-front/l2r-networks"
import { SidebarContent, useIsMobileDevice } from "@l2r-front/l2r-ui"
import { useNavigateWithSearchParams } from "@l2r-front/l2r-utils"

import { I18N_NAMESPACE } from "../../../../common/constants/i18n"
import { VerticalSignsSidebarComponents } from "../../components/VerticalSignsSidebarComponents"
import { useVerticalSigningStateContext } from "../../contexts/VerticalSigningContext"
import * as Styled from "./VerticalSigningSidebar.styled"

export function VerticalSigningSidebar() {

    const { t } = useTranslation([I18N_NAMESPACE])
    const { ascendantCodes } = useVerticalSigningStateContext()
    const isMobile = useIsMobileDevice()
    const { getMapRef } = useMapDispatchContext()
    const { road } = useParams()
    const { setRoadSearched } = useNetworksDispatchContext()
    const navigate = useNavigateWithSearchParams()

    const additionalSidebars = useMemo(() => {
        if (isMobile) {
            return null
        }

        return ascendantCodes
            .filter((_, index) => (ascendantCodes.length - index) % 2 === 0)
            .reverse()
            .map((code, index) => (
                <Styled.AdditionalSidebar key={code} index={index} >
                    <Styled.VerticalSignTypeList baseVerticalSignTypeCode={code} />
                </Styled.AdditionalSidebar>
            ))
    }, [ascendantCodes, isMobile])

    const navigateBack = useCallback(() => {
        setRoadSearched(null)
        navigate("..")
    }, [navigate, setRoadSearched])

    useEffect(() => {
        const map = getMapRef()?.getMap()
        if (map?.style) {
            map?.resize()
        }
    }, [ascendantCodes, getMapRef])

    return (
        <SidebarContent
            id="verticalSigning-sidebar"
            title={t(I18N_NAMESPACE, `containers.verticalSigningSidebar.${road ? "roadTitle" : "title"}`)}
            onBack={road ? navigateBack : null}
        >
            <Styled.Container>
                <VerticalSignsSidebarComponents />
            </Styled.Container>
            {additionalSidebars}
        </SidebarContent>
    )
}