import { useMemo } from "react"

import { useTranslation } from "@l2r-front/l2r-i18n"
import { PropTypes } from "@l2r-front/l2r-proptypes"
import { Typography } from "@l2r-front/l2r-ui"

import { I18N_NAMESPACE } from "../../constants/i18n"
import { RoadDetailCity } from "../../containers/RoadDetailCity"
import { RoadDetailLength } from "../../containers/RoadDetailLength"
import * as Styled from "./RoadDetailInfo.styled"

export const RoadDetailInfo = (props) => {

    const {
        className,
        lengthLabel,
        LengthComponent,
        road,
    } = props

    const { t } = useTranslation(I18N_NAMESPACE)

    const detailComponent = useMemo(() => {
        if (LengthComponent && lengthLabel) {
            return <Styled.ListElement>
                <Styled.Label variant="Small accent">{lengthLabel}&nbsp;</Styled.Label>
                {LengthComponent}
            </Styled.ListElement >
        } else if (road) {
            return <Styled.ListElement>
                <Styled.Label variant="Small accent">{t(I18N_NAMESPACE, "containers.segmentDetailInfo.length")}&nbsp;</Styled.Label>
                <RoadDetailLength road={road} />
            </Styled.ListElement>
        }
        return null
    }, [LengthComponent, lengthLabel, road, t])

    return (
        <div className={className} id="road-detail-info">
            <Styled.List>
                <Styled.ListElement>
                    <Styled.Label variant="Small accent">{t(I18N_NAMESPACE, "containers.segmentDetailInfo.road")}&nbsp;</Styled.Label>
                    <Typography id="road-detail-road" variant="Regular">{road}</Typography>
                </Styled.ListElement>
                <Styled.ListElement>
                    <Styled.Label variant="Small accent">{t(I18N_NAMESPACE, "containers.segmentDetailInfo.city")}&nbsp;</Styled.Label>
                    <RoadDetailCity road={road} />
                </Styled.ListElement>
                {detailComponent}
            </Styled.List>
        </div>
    )
}

RoadDetailInfo.defaultProps = {
    LengthComponent: null,
}

RoadDetailInfo.propTypes = {
    className: PropTypes.string,
    lengthLabel: PropTypes.string,
    LengthComponent: PropTypes.element,
    road: PropTypes.string,
}