export const verticalSignsMulhouse = [
    {
        "id": "504d0a0c-c397-4665-8560-daa8f59a3e2b",
        "type": "Feature",
        "geometry": {
            "type": "Point",
            "coordinates": [
                7.340147916360933,
                47.759813595480296,
            ],
        },
        "linear_location": [
            {
                "typeid": 0,
                "road": "BOULEVARD DES ALLIES",
                "start": 601,
                "end": 602,
            },
        ],
        "properties": {
            "is_multi": false,
            "vertical_signs": [
                {
                    "vertical_sign_type": "A13a",
                    "content": null,
                    "condition": 10,
                },
            ],
        },
    },
    {
        "id": "a9e74e81-83ab-4d5e-8c51-33c726ce4019",
        "type": "Feature",
        "geometry": {
            "type": "Point",
            "coordinates": [
                7.340189727933408,
                47.759778658512076,
            ],
        },
        "linear_location": [
            {
                "typeid": 0,
                "road": "BOULEVARD DES ALLIES",
                "start": 596,
                "end": 597,
            },
        ],
        "properties": {
            "is_multi": true,
            "vertical_signs": [
                {
                    "vertical_sign_type": "A13a",
                    "content": null,
                    "condition": 10,
                },
                {
                    "vertical_sign_type": "A13b",
                    "content": null,
                    "condition": 20,
                },
                {
                    "vertical_sign_type": "A13b",
                    "content": null,
                    "condition": 30,
                },
                {
                    "vertical_sign_type": "M1_1",
                    "content": null,
                    "condition": 10,
                },
            ],
        },
    },
]

export const allVerticalSignPoles = {
    "mulhouse": verticalSignsMulhouse,
}

const mapToFeatureItem = (feature) => {
    const {
        layer,
        ...data
    } = feature.properties
    return {
        uuid: feature.id,
        layer: layer,
        data: data,
        geometry: feature.geometry,
        linear_locations: feature.linear_location,
    }
}

export const getVerticalSignPoles = (project, asGeoJson) => {
    const verticalPoles = allVerticalSignPoles[project]
    if (asGeoJson) {
        return {
            "type": "FeatureCollection",
            "features": verticalPoles,
        }
    } else {
        return verticalPoles.map(feature => mapToFeatureItem(feature))
    }
}

export const getVerticalSignPole = (project, poleId) => {
    const verticalPoles = allVerticalSignPoles[project]
    const verticalPole = verticalPoles.find(verticalPole => poleId === verticalPole.id)
    return mapToFeatureItem(verticalPole)
}
