import { useIsMobileDevice } from "@l2r-front/l2r-ui"

import { CityDesktopRouter } from "./common/routers/CityDesktopRouter"
import { CityMobileRouter } from "./common/routers/CityMobileRouter"
import { VerticalSigningContextProvider } from "./modules/verticalSigning/contexts/VerticalSigningContext"

export function L2rAppCity() {

    const isMobile = useIsMobileDevice()

    return (
        <VerticalSigningContextProvider>
            {isMobile ? <CityMobileRouter /> : <CityDesktopRouter />}
        </VerticalSigningContextProvider>
    )
}
