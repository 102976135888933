
import { MapContextProvider } from "@l2r-front/l2r-map"
import { NetworksContextProvider } from "@l2r-front/l2r-networks"

import { AuthenticatedRoutes } from "./AuthenticatedRoutes"

export const AuthenticatedRouter = () => {

    return (
        <MapContextProvider>
            <NetworksContextProvider>
                <AuthenticatedRoutes />
            </NetworksContextProvider>
        </MapContextProvider>
    )
}
