import { useParams, Navigate } from "react-router-dom"

export const NavigateWithParams = (props) => {
    const {
        to, 
        ...otherProps
    } = props

    const params = useParams()
    const pathParams = [...to.matchAll(/(:[^[/?]+)/g)]

    let newTo = to
    for (const [param, _] of pathParams) {
        newTo = newTo.replace(param, params[param.slice(1)])
    }

    return <Navigate to={newTo} {...otherProps} />
}