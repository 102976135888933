import * as turf from "@turf/turf"

import { useAuthenticatedQuery } from "@l2r-front/l2r-auth"

import { networksQueryKeys } from "./queryKeys"

export function useNetworksQuery(filters, queryParameters, config) {
    const url = "/network/networks/"
    const queryKey = networksQueryKeys["list"]({ filters })

    const { data, isLoading, isError } = useAuthenticatedQuery(
        url,
        queryKey,
        queryParameters,
        config,
    )

    if (data) {
        data.forEach(network => {
            if (!network.boundingBox?.coordinates?.length) {
                network.boundingBox = turf.lineString([[-1, 1], [-1, 1]])
            }
        })
    }

    return { data, isLoading, isError }
}
