import { useMemo } from "react"

import { PropTypes } from "@l2r-front/l2r-proptypes"

import {
    VERTICAL_SIGNING_CONDITION_BAD,
    VERTICAL_SIGNING_CONDITION_GOOD,
    VERTICAL_SIGNING_CONDITION_MEDIUM,
    VERTICAL_SIGNING_CONDITIONS_VALUES,
} from "../../constants/verticalSigningConditions"
import { useVerticalSigningDispatchContext, useVerticalSigningStateContext } from "../../contexts/VerticalSigningContext"
import { VerticalSignsCount } from "../VerticalSignsCount"
import * as Styled from "./VerticalSignsGrading.styled"

export function VerticalSignsGrading(props) {

    const {
        className,
        count,
        ...rest
    } = props

    const { setFilters } = useVerticalSigningDispatchContext()
    const { filters } = useVerticalSigningStateContext()
    const orderedCount = useMemo(() => {
        return Object.entries(count)
            .sort(([keyA], [keyB]) => keyB - keyA)
    }, [count])

    const totalCount = useMemo(() => Object.values(count).reduce((acc, v) => acc + v, 0), [count])
    const verticalSignsGradingBarItemsCount = Object.values(count).filter(c => c && c > 0).length
    const verticalSignsGradingBarItems = useMemo(() => {
        return orderedCount
            .filter(([_, value]) => !!value)
            .map(([dataKey, dataValue], index) => {
                const color = VERTICAL_SIGNING_CONDITIONS_VALUES[dataKey].color
                const width = dataValue / totalCount * 100

                return <Styled.VerticalSignsGradingBarItem
                    color={color}
                    key={`verticalSignsGradingItem-${dataKey}`}
                    index={index}
                    width={width}
                />
            })
    }, [orderedCount, totalCount])

    const verticalSignsCounts = useMemo(() => orderedCount.map(([dataKey, dataValue]) => {
        const color = VERTICAL_SIGNING_CONDITIONS_VALUES[dataKey].color
        const label = VERTICAL_SIGNING_CONDITIONS_VALUES[dataKey].label
        const filterConditionArray = (filters.conditions || "").split(",")

        return <VerticalSignsCount
            color={color}
            count={dataValue}
            key={`verticalSignsCount-${dataKey}`}
            label={label}
            onClick={() => setFilters({ condition: dataKey })}
            selected={filterConditionArray.includes(dataKey)}
        />

    }), [filters, orderedCount, setFilters])

    return <div className={className} {...rest}>
        <Styled.Mask>
            <Styled.VerticalSignsGradingBarWrapper itemsCount={verticalSignsGradingBarItemsCount}>
                <Styled.EmptyBarItem />
                {verticalSignsGradingBarItems}
            </Styled.VerticalSignsGradingBarWrapper>
        </Styled.Mask>
        <Styled.VerticalSignsCountsWrapper>
            {verticalSignsCounts}
        </Styled.VerticalSignsCountsWrapper>
    </div>
}

VerticalSignsGrading.propTypes = {
    className: PropTypes.string,
    count: PropTypes.shape({
        [VERTICAL_SIGNING_CONDITION_BAD]: PropTypes.number.isRequired,
        [VERTICAL_SIGNING_CONDITION_MEDIUM]: PropTypes.number.isRequired,
        [VERTICAL_SIGNING_CONDITION_GOOD]: PropTypes.number.isRequired,
    }).isRequired,
}