import { useEffect, useMemo, useCallback } from "react"

import * as turf from "@turf/turf"
import * as htmlToImage from "html-to-image"
import { useParams } from "react-router-dom"

import { useTranslation } from "@l2r-front/l2r-i18n"
import { Source, useMapDispatchContext } from "@l2r-front/l2r-map"
import { useTheme } from "@l2r-front/l2r-ui"

import { I18N_NAMESPACE } from "../../../../common/constants/i18n"
import { findTypeByCode } from "../../../utils/findTypeByCode"
import { DEFAULT_SIGN_CODE, DEFAULT_SIGN_ICON } from "../../constants/defaultIcon"
import { VERTICAL_SIGNING_CONDITIONS_VALUES } from "../../constants/verticalSigningConditions"
import { useVerticalSigningStateContext } from "../../contexts/VerticalSigningContext"
import { useVerticalSignPoles } from "../../hooks/queries/verticalSigning/useVerticalSignPoles"
import { useVerticalSignTypesInProject } from "../../hooks/queries/verticalSigning/useVerticalSignTypesInProject"

const MULTI_SIGNS_CODE = "MULTI_SIGNS"
const MULTI_SIGNS_ICON = "assets/resources/MultiSigns.png"

const ICON_SIZE = 60
const COLOR_SIZE = 20

export const ENABLED_STATE = "1"
export const DISABLED_STATE = "0"

export function VerticalSignPolesSource(props) {

    const { t } = useTranslation(I18N_NAMESPACE)
    const { getMapRef, setError: setMapError } = useMapDispatchContext()
    const theme = useTheme()
    const { filters } = useVerticalSigningStateContext()
    const { poleId } = useParams()

    const { data: verticalSignTypes } = useVerticalSignTypesInProject()

    const { data } = useVerticalSignPoles({
        as_geojson: true,
    }, {
        onError: () => setMapError(true),
        errorSnackbarMessage: t(I18N_NAMESPACE, "containers.verticalSignPolesLayer.error"),
    })

    const { descendantCodes } = useVerticalSigningStateContext()

    const createMapIcon = useCallback((code, icon, condition, state, map, theme) => {
        const color = theme.palette[VERTICAL_SIGNING_CONDITIONS_VALUES?.[condition]?.color]?.main
        const opacity = state === ENABLED_STATE ? 1 : 0.5
        const iconId = code + "#" + condition + "#" + state
        const container = document.createElement("div")
        container.innerHTML = `
            <div style="background-color: white; height: 100%; width: 100%; border-radius: 50%;">
                <div style="background-color: ${color}; opacity:${opacity}; height: 100%; width: 100%; border-radius: 50%; position:absolute;top:0px;left:0px"></div>
                <div style="background-color: white; height: calc(100% - ${COLOR_SIZE}px); width: calc(100% - ${COLOR_SIZE}px); border-radius: 50%; position:absolute;top:${COLOR_SIZE / 2}px;left:${COLOR_SIZE / 2}px">
                    <img src=${icon} style="width:calc(sqrt(2) * ${(ICON_SIZE - COLOR_SIZE) / 2}px); height:calc(sqrt(2) * ${(ICON_SIZE - COLOR_SIZE) / 2}px); position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%);object-fit: contain; opacity:${opacity};" />
                </div>
            </div>
        `

        htmlToImage.toPng(container, {
            height: ICON_SIZE,
            width: ICON_SIZE,
            canvasHeight: ICON_SIZE,
            canvasWidth: ICON_SIZE,
            pixelRatio: 1,
            skipFonts: true,
        })
            .then(function (dataUrl) {
                window.generatedIcons[iconId] = dataUrl
                map.loadImage(
                    dataUrl,
                    (error, icon) => {
                        if (error) {
                            return console.error(error)
                        }

                        if (!map.hasImage(iconId)) {
                            map.addImage(iconId, icon)
                        }
                    },
                )
            })
            .catch(() => {
                createMapIcon(code, DEFAULT_SIGN_ICON, condition, state, map, theme)
            })
    }, [])

    useEffect(() => {
        const map = getMapRef()?.getMap()

        if (!map || !theme) {
            return
        }

        if (!window.generatedIcons) {
            window.generatedIcons = {}
        }

        Object.keys(VERTICAL_SIGNING_CONDITIONS_VALUES).forEach(condition => {
            [ENABLED_STATE, DISABLED_STATE].forEach(state => {
                const iconId = DEFAULT_SIGN_CODE + "#" + condition + "#" + state
                if (!window.generatedIcons[iconId]) {
                    createMapIcon(DEFAULT_SIGN_CODE, DEFAULT_SIGN_ICON, condition, state, map, theme)
                }
            })
        })
    }, [getMapRef, theme, createMapIcon])

    useEffect(() => {
        const map = getMapRef()?.getMap()

        if (!map || !theme || !verticalSignTypes) {
            return
        }

        const loadIcon = (e) => {
            const iconId = e.id
            const [typeCode, condition, state] = iconId.split("#")

            if (!typeCode || Object.keys(VERTICAL_SIGNING_CONDITIONS_VALUES).indexOf(condition) < 0) {
                return
            }

            if (!window.generatedIcons) {
                window.generatedIcons = {}
            }

            if (window.generatedIcons[iconId]) {
                map.loadImage(
                    window.generatedIcons[iconId],
                    (error, icon) => {
                        if (error) {
                            return console.error(error)
                        }

                        if (!map.hasImage(iconId)) {
                            map.addImage(iconId, icon)
                        }
                    },
                )
            } else {
                const icon = typeCode === MULTI_SIGNS_CODE ? MULTI_SIGNS_ICON :
                    typeCode === DEFAULT_SIGN_CODE ? DEFAULT_SIGN_ICON :
                        findTypeByCode(typeCode, verticalSignTypes)?.icon ? findTypeByCode(typeCode, verticalSignTypes)?.icon : DEFAULT_SIGN_ICON
                createMapIcon(typeCode, icon, condition, state, map, theme)
            }
        }

        map?.on("styleimagemissing", loadIcon)

        return () => map?.off("styleimagemissing", loadIcon)

    }, [getMapRef, theme, verticalSignTypes, createMapIcon])

    const getIconOfSigns = (signs) => {
        return signs.reduce((acc, sign) => {
            if (acc === null) {
                return sign.vertical_sign_type
            } else {
                return MULTI_SIGNS_CODE
            }
        }, null)
    }

    // Need to copy id into properties because of that mapbox issue: 
    // link:https://github.com/mapbox/mapbox-gl-js/issues/2716
    const sanitizedData = useMemo(() => {
        if (!data || !verticalSignTypes) {
            return null
        }

        const dataFeatures = []
        turf.geomEach(data, (currentGeometry, featureIndex, featureProperties, featureBBox, featureId) => {
            const feature = data.features[featureIndex]
            const filterConditions = filters?.conditions?.length ? filters?.conditions.split(",") : null
            const selectedSigns = featureProperties.vertical_signs
                .filter(sign => {
                    const isFilteredByType = descendantCodes.indexOf(sign.vertical_sign_type) >= 0
                    const isSelectedPole = featureId === poleId
                    const isFilteredByCondition = !filterConditions || filterConditions?.includes(sign.condition.toString())

                    return isSelectedPole || (isFilteredByType && isFilteredByCondition)
                })

            if (selectedSigns.length) {
                const globalCondition = selectedSigns.reduce((acc, currentSignPole) => {
                    const currentSignType = findTypeByCode(currentSignPole.vertical_sign_type, verticalSignTypes)
                    if (currentSignPole.condition === acc || !currentSignType.is_main) {
                        return acc
                    } else {
                        return -1
                    }
                }, selectedSigns.length ? selectedSigns[0].condition : -1)
                const selectedSignsForIcon = selectedSigns.length === 1 ? selectedSigns : selectedSigns.filter(sign => {
                    const type = findTypeByCode(sign.vertical_sign_type, verticalSignTypes)
                    return type.is_main
                })
                const globalIcon = getIconOfSigns(selectedSignsForIcon)
                dataFeatures.push({
                    id: featureId,
                    bbox: featureBBox,
                    geometry: currentGeometry,
                    properties: {
                        ...featureProperties,
                        vertical_signs: [...selectedSigns],
                        uuid: featureId,
                        linearLocation: feature.linear_location,
                        globalCondition: globalCondition,
                        globalIcon: globalIcon,
                    },
                })
            }
        })
        return turf.featureCollection(dataFeatures)
    }, [data, descendantCodes, filters, poleId, verticalSignTypes])

    if (!sanitizedData) {
        return null
    }

    return (
        <Source {...props} type="geojson" data={sanitizedData} tolerance={.001} />
    )
}